import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AiFillHeart, AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import { addToWishlist, removeFromWishlist } from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import MobileProductCard from "./MobileProductCard";

const ProductCard = ({ data, isEvent }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (isMobile) {
    return <MobileProductCard data={data} isEvent={isEvent} />;
  }

  return (
    <div
      className="w-[260px] h-[410px] bg-white rounded-lg p-3 relative cursor-pointer transition-all duration-300 ease-in-out hover:shadow-lg hover:shadow-gray-300 hover:scale-105 border"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link
        to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}
      >
        <img
          src={`${data.images && data.images[0]?.url}`}
          alt=""
          className="w-full h-[310px] object-contain transition-transform duration-300 ease-in-out hover:scale-110 mix-blend-multiply"
          style={{ backgroundColor: '#f1f1f1' }}
        />
      </Link>

      <div className="mt-3">
        <Link
          to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}
        >
          <h4 className="font-[500] text-[14px]">
            {data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}
          </h4>
          <div className="py-1 flex items-center justify-between">
            <div className="flex">
              <h6 className="text-[16px] font-[600]">
                RWF {formatPrice(data.originalPrice === 0 ? data.originalPrice : data.discountPrice)}
              </h6>
            </div>
            <span className="font-[400] text-[13px] text-[#68d284]">
              {data?.sold_out} sold
            </span>
          </div>
        </Link>
      </div>

      {isHovered && (
        <div className="bg-[#29625d] shadow-md absolute bottom-[80px] left-0 w-full p-2 flex justify-around items-center opacity-80">
          {click ? (
            <AiFillHeart
              size={30}
              className="cursor-pointer text-xl bg-white p-1 rounded-full"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "black"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={30}
              className="cursor-pointer text-xl bg-white p-1 rounded-full"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "black"}
              title="Add to wishlist"
            />
          )}

          <AiOutlineShoppingCart
            size={30}
            className="cursor-pointer text-xl bg-white p-1 rounded-full"
            onClick={() => addToCartHandler(data._id)}
            color="black"
            title="Add to cart"
          />
        </div>
      )}
    </div>
  );
};

export default ProductCard;
