import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import Ratings from '../../Products/Ratings';
import { addToWishlist, removeFromWishlist } from '../../../redux/actions/wishlist';
import { addTocart } from '../../../redux/actions/cart';
import MobileProductCard from '../ProductCard/MobileProductCard';
import { useMediaQuery } from 'react-responsive';

const ProductList = ({ products }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <div className={`p-4 w-full mx-auto ${isMobile ? 'grid grid-cols-2 gap-4' : 'flex flex-col gap-4'}`}>
      {products.map((product) => {
        const isInWishlist = wishlist.some((item) => item._id === product._id);
        
        const handleWishlistToggle = () => {
          if (isInWishlist) {
            dispatch(removeFromWishlist(product));
            toast.info('Removed from wishlist!');
          } else {
            dispatch(addToWishlist(product));
            toast.success('Added to wishlist!');
          }
        };

        const addToCartHandler = (id) => {
          const isItemExists = cart.find((i) => i._id === id);
          if (isItemExists) {
            toast.error('Item already in cart!');
          } else {
            if (product.stock < 1) {
              toast.error('Product stock limited!');
            } else {
              const cartData = { ...product, qty: 1 };
              dispatch(addTocart(cartData));
              toast.success('Item added to cart successfully!');
            }
          }
        };

        if (isMobile) {
          return <MobileProductCard key={product._id} data={product} />;
        }

        const isNew = new Date() - new Date(product.createdAt) < 24 * 60 * 60 * 1000; // Check if created within last 24 hours
        const discount = product.originalPrice - product.discountPrice;
        const showDiscountText = discount > 10000;

        return (
          <div key={product._id} className="flex border transition-shadow duration-300 w-full h-[270px] max-w-full mx-auto relative">
            {product.featured && (
              <span className="absolute top-2 left-2 bg-red-500 text-white text-xs px-2 py-1 rounded">
                Featured
              </span>
            )}
            {isNew && (
              <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded">
                New
              </span>
            )}
            <Link to={`/product/${product._id}`} className="flex-shrink-0 flex items-center h-full">
              <img
                src={product.images[0]?.url}
                alt={product.name}
                className="h-full w-[250px] object-contain bg-[#f1f1f1]"
              />
            </Link>
            <div className="flex-grow flex flex-col justify-between p-6">
              <div>
                <Link to={`/product/${product._id}`}>
                  <h2 className="font-medium text-xl mb-2">
                    {product.name.length > 360 ? `${product.name.slice(0, 330)}...` : product.name}
                  </h2>
                </Link>
                
                <div className="flex justify-between items-center mb-2">
                  <h5 className="text-md font-semibold">
                    RWF {formatPrice(product.discountPrice ? product.discountPrice : product.originalPrice)}
                  </h5>
                  <span className="font-medium text-sm mt-4 text-[#68d284]">
                    {product.stock} In Stock
                  </span>
                </div>
                <div className="flex mt-1">
                  <Ratings rating={product.ratings} /> {product.reviews.length} Reviews
                </div>
                {product.bestdeal && (
                  <p className="text-sm text-[#29625d] font-bold">Best Deal</p>
                )}
                {product.bestdeal && showDiscountText && (
                  <p className="text-sm text-red-500 font-bold">On Discount</p>
                )}
              </div>

              
              <div className="flex gap-3 mt-3">
                {isInWishlist ? (
                  <AiFillHeart
                    size={42}
                    className="cursor-pointer bg-gray-100 p-1 rounded-full"
                    onClick={handleWishlistToggle}
                    color="red"
                    title="Remove from wishlist"
                  />
                ) : (
                  <AiOutlineHeart
                    size={42}
                    className="cursor-pointer bg-gray-100 p-1 rounded-full"
                    onClick={handleWishlistToggle}
                    title="Add to wishlist"
                  />
                )}
                <button
                  className="bg-[#29625d] text-white py-2 px-4 rounded-full transition duration-300 hover:bg-black"
                  onClick={() => addToCartHandler(product._id)}
                  title="Add to cart"
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductList;
