import React from 'react';
import { Link } from 'react-router-dom';
const EventsBanner = () => {
  return (
    <div className="relative h-[600px] w-[92%] bg-cover bg-center p-2 m-6 ml-auto mr-auto" style={{ backgroundImage: `url('https://img.freepik.com/free-psd/psd-club-dj-party-flyer-social-media-post-template_505751-3273.jpg?t=st=1729865445~exp=1729869045~hmac=c325836abbcbb9cda0d1eacd100b4b0b0f2b83c6de3de5044db90eccac8809e1&w=826')` }}>
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
        <h1 className="text-white text-4xl md:text-6xl font-bold mb-4">We have events for ya!</h1>
        <p className="text-white text-lg md:text-xl mb-6 text-center">
          Don’t miss out on the ultimate experience. Get your tickets now!
        </p>
        <Link to='/events'>
        <span href="#tickets" className="bg-yellow-500 text-white py-2 px-4 rounded-lg text-lg hover:bg-yellow-400 transition duration-300">
          Get Tickets
        </span>
        </Link>
      </div>
    </div>
  );
}

export default EventsBanner;
