import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductList from "../components/Route/ProductList/ProductList";
import DropDownFilter from "../components/Layout/DropDownFilter";
import { categoriesData } from "../static/data"; // Import your categories data
import { RiEqualizerLine } from "react-icons/ri";

const ProductsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const searchTerm = searchParams.get("search") || ""; // Extract search term
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility

  // New filter states
  const [priceRange, setPriceRange] = useState([1, 10000000]);
  const [condition, setCondition] = useState("");
  const [isWholesale, setIsWholesale] = useState(false);
  const [isFlashSale, setIsFlashSale] = useState(false);
  const [isDailyDeal, setIsDailyDeal] = useState(false);
  const [isPriceFiltered, setIsPriceFiltered] = useState(false); // Track if price filter has been adjusted

  useEffect(() => {
    if (isLoading) return; // Prevent running while loading
    if (!Array.isArray(allProducts)) {
      console.error("allProducts is not an array");
      return;
    }

    const filteredData = allProducts.filter((product) => {
      const matchesCategory = categoryData ? product.category === categoryData : true;
      const matchesSearch =
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.tags.toLowerCase().includes(searchTerm.toLowerCase());
        
      const matchesPrice =
        !isPriceFiltered || 
        (product.discountPrice >= priceRange[0] && product.discountPrice <= priceRange[1]);

      return matchesCategory && matchesSearch && matchesPrice;
    });

    setData(filteredData);
  }, [allProducts, categoryData, searchTerm, isLoading, priceRange, isPriceFiltered]); 

  const handleCategoryChange = (category) => {
    const newParams = new URLSearchParams(searchParams);
    if (category === categoryData) {
      newParams.delete("category"); // Remove category if it's already selected
    } else {
      newParams.set("category", category); // Set the new category
    }
    setSearchParams(newParams); // Update the URL parameters
  };

  const handlePriceChange = (newRange) => {
    setPriceRange(newRange);
    setIsPriceFiltered(true); // Mark that the price filter has been adjusted

    const newParams = new URLSearchParams(searchParams);
    newParams.set("priceMin", newRange[0]); // Add or update min price
    newParams.set("priceMax", newRange[1]); // Add or update max price
    setSearchParams(newParams); // Update the URL parameters
  };

  const handleConditionChange = (condition) => {
    const newParams = new URLSearchParams(searchParams);
    if (condition) {
      newParams.set("condition", condition); // Set condition if it exists
    } else {
      newParams.delete("condition"); // Remove condition if it's empty
    }
    setCondition(condition);
    setSearchParams(newParams); // Update the URL parameters
  };

  const handleWholesaleChange = () => {
    const newWholesaleState = !isWholesale;
    setIsWholesale(newWholesaleState);

    const newParams = new URLSearchParams(searchParams);
    newParams.set("wholesale", newWholesaleState); // Update wholesale filter
    setSearchParams(newParams); // Update the URL parameters
  };

  const handleFlashSaleChange = () => {
    const newFlashSaleState = !isFlashSale;
    setIsFlashSale(newFlashSaleState);

    const newParams = new URLSearchParams(searchParams);
    newParams.set("flashSale", newFlashSaleState); // Update flash sale filter
    setSearchParams(newParams); // Update the URL parameters
  };

  const handleDailyDealChange = () => {
    const newDailyDealState = !isDailyDeal;
    setIsDailyDeal(newDailyDealState);

    const newParams = new URLSearchParams(searchParams);
    newParams.set("dailyDeal", newDailyDealState); // Update daily deal filter
    setSearchParams(newParams); // Update the URL parameters
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="bg-white min-h-screen">
          <Header activeHeading={3} />
          <div className="flex justify-center items-start flex-wrap w-full mb-10">
            {/* Filter Sidebar for Desktop */}
            <div className="hidden lg:block w-[20%] p-6 mx-2 lg:mx-0">
              <h2 className="font-bold text-lg mb-4 text-gray-800">Filter Options</h2>

              {/* Category Filter */}
              <div className="mb-6">
                <h3 className="font-semibold text-black">Category</h3>
                <div className="flex flex-col">
                  {categoriesData.map((category) => (
                    <label key={category.id} className="flex items-center mb-2 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={categoryData === category.title}
                        onChange={() => handleCategoryChange(category.title)}
                        className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <span className="text-gray-600 text-[14px]">{category.title}</span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Price Range Filter */}
              <div className="mb-6">
                <h3 className="font-semibold text-gray-700">Price Range</h3>
                <input
                  type="range"
                  min="0"
                  max="10000000"
                  value={priceRange[0]}
                  onChange={(e) => handlePriceChange([+e.target.value, priceRange[1]])}
                  className="w-full mb-2"
                />
                <input
                  type="range"
                  min="0"
                  max="10000000"
                  value={priceRange[1]}
                  onChange={(e) => handlePriceChange([priceRange[0], +e.target.value])}
                  className="w-full mb-4"
                />
                <p className="text-black text-[14px]">Selected Price: RWF {priceRange[0]} - RWF {priceRange[1]}</p>
              </div>

              {/* Condition Filter */}
              <div className="mb-6">
                <h3 className="font-semibold text-gray-700">Condition</h3>
                <select
                  value={condition}
                  onChange={(e) => handleConditionChange(e.target.value)}
                  className="w-full mb-2 border rounded-md shadow-sm"
                >
                  <option value="">All Conditions</option>
                  <option value="new">New</option>
                  <option value="used">Used</option>
                </select>
              </div>

              {/* Wholesale Checkbox */}
              <div className="mb-6">
                <label className="flex items-center mb-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isWholesale}
                    onChange={handleWholesaleChange}
                    className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <span className="text-gray-600 text-[14px]">Wholesale</span>
                </label>
              </div>

              {/* Flash Sale Checkbox */}
              <div className="mb-6">
                <label className="flex items-center mb-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isFlashSale}
                    onChange={handleFlashSaleChange}
                    className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <span className="text-gray-600 text-[14px]">Flash Sale</span>
                </label>
              </div>

              {/* Daily Deal Checkbox */}
              <div className="mb-6">
                <label className="flex items-center mb-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isDailyDeal}
                    onChange={handleDailyDealChange}
                    className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <span className="text-gray-600 text-[14px]">Daily Deal</span>
                </label>
              </div>
            </div>

            {/* Button to toggle Dropdown Filter for Mobile */}
            <div className="block lg:hidden w-full p-4">
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="bg-[#29625d] text-white py-2 px-4 rounded"
              >
                <RiEqualizerLine />
              </button>
              {dropdownOpen && (
                <DropDownFilter
                  categoryData={categoryData}
                  handleCategoryChange={handleCategoryChange}
                />
              )}
            </div>

            {/* Product List Area */}
            <div className="flex-grow w-full lg:w-[70%] p-4 mx-2 lg:mx-0">
              {data && data.length > 0 ? (
                <ProductList products={data} />
              ) : (
                <h1 className="text-center w-full pb-[100px] text-[20px]">
                  No products Found!
                </h1>
              )}
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default ProductsPage;


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































