import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const FeaturedProduct = () => {
  const { allProducts } = useSelector((state) => state.products);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8; // Set to 8
  const isDesktop = useMediaQuery({ query: "(min-width: 901px)" });

  if (!allProducts) {
    return (
      <div className="flex justify-center items-center h-40">
        <p>Loading...</p>
      </div>
    );
  }

  // Filter featured products
  const featuredProducts = allProducts.filter((product) => product.featured);

  // Calculate the index of the first and last product on the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = featuredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  // Calculate the total number of pages
  const totalPages = Math.ceil(featuredProducts.length / productsPerPage);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className={`${styles.section} mb-20`}>
        {/* Hide title on desktop */}
        {!isDesktop && (
          <div className={`${styles.heading} text-sm md:text-base lg:text-lg text-start`}>
            <h1>Featured Products</h1>
          </div>
        )}

        {isDesktop && (
          <div className="relative mb-5 h-[600px]"> {/* Set height for the banner */}
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: "url('https://img.freepik.com/premium-photo/green-bag-with-green-yellow-pattern-it_1063883-3551.jpg?w=826')",
                backgroundAttachment: 'fixed',
              }}
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center">
              <h1 className="text-white text-4xl md:text-6xl font-bold mb-4">Special Offer!</h1>
              <p className="text-white text-lg">Don't miss out on our featured products.</p>
             <Link to='/best-selling'>
             <button className="bg-[#29625d] text-white py-2 px-4 rounded-full transition duration-300 hover:bg-black mt-4">
                Best Deals
              </button>
             </Link>
            </div>
          </div>
        )}

        {/* Hide products on desktop */}
        {!isDesktop && (
          <div className="w-full flex justify-center items-start flex-wrap gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-5">
            {currentProducts.length > 0 ? (
              currentProducts.map((product, index) => (
                <div key={index}>
                  <ProductCard data={product} />
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center h-40 w-full">
                <p>No featured products available</p>
              </div>
            )}
          </div>
        )}

        {/* Pagination */}
        {!isDesktop && totalPages > 1 && (
          <div className="flex justify-center items-center gap-4 mb-5">
            <span
              onClick={handlePreviousPage}
              className={`cursor-pointer ${currentPage === 1 ? "text-gray-400" : "text-black"}`}
              disabled={currentPage === 1}
            >
              <AiOutlineArrowLeft /> Previous
            </span>
            <span>{`Page ${currentPage} of ${totalPages}`}</span>
            <span
              onClick={handleNextPage}
              className={`cursor-pointer ${currentPage === totalPages ? "text-gray-400" : "text-black"}`}
              disabled={currentPage === totalPages}
            >
              Next <AiOutlineArrowRight />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeaturedProduct;
