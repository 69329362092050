import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../../../styles/styles";

// Define categories with subcategories
const initialCategories = [
  {
    title: "Electronics",
    image: "https://manofmany.com/wp-content/uploads/2021/01/Tech-Essentials.png",
    subcategories: [
      {
        title: "Laptops",
        image: "https://img.freepik.com/free-psd/close-up-silver-laptop-isolated_23-2151869000.jpg?t=st=1729869647~exp=1729873247~hmac=1611f205bb78c445497ac800bac1d53d8bbd5ccfb1cc487fff8bf1543e106295&w=1380",
      },
      {
        title: "Phones",
        image: "https://www.telstra.com.au/content/dam/tcom/devices/mobile/mhdwhst-px9px/porcelain/landscape-google-pixel9ProXL-porcelain-03-900x1200.jpg",
      },
      {
        title: "Headphones",
        image: "https://media.graphassets.com/Ge4V4qo0TGuG9Tna0e4g",
      },
    ],
  },
  {
    title: "Clothing",
    image: "https://images.pexels.com/photos/3812433/pexels-photo-3812433.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    subcategories: [
      {
        title: "Shoes",
        image: "https://img.freepik.com/free-photo/fashion-pink-glamour-women-heels_1203-6509.jpg?t=st=1729869025~exp=1729872625~hmac=0e90e35dd67664a5c2927ed4737f33478a161216527521fa6abb2306293b5dab&w=1380",
      },
      {
        title: "Sweaters",
        image: "https://img.freepik.com/free-photo/white-red-knitted-christmas-jumper-isolated-white-background_125540-5392.jpg?t=st=1729869554~exp=1729873154~hmac=ffc0b77d00de6583e21863962372a79288b91dad41f3fb3d260617a2fad5316d&w=1380",
      },
      {
        title: "Hats",
        image: "https://img.freepik.com/free-photo/set-two-trucker-hats-with-mesh-back_23-2149410050.jpg?t=st=1729869609~exp=1729873209~hmac=1def032968679e08da3132771ddec577880aee57322e8dc6f12852871991a2c7&w=1380",
      },
    ],
  },
  {
    title: "Home",
    image: "https://images.pexels.com/photos/4700383/pexels-photo-4700383.jpeg?auto=compress&cs=tinysrgb&w=600",
    subcategories: [
      {
        title: "Refrigerators",
        image: "https://s3.amazonaws.com/productuploader-uploads/staging/2/Image/9369674_1719300231_LG_Electronics-123019763-md07500055-zoom-02-jpg",
      },
      {
        title: "Furniture",
        image: "https://m.media-amazon.com/images/I/61X6up+dzrL.jpg",
      },
      {
        title: "Microwaves",
        image: "https://img.freepik.com/free-vector/microwave-oven-with-light-inside-isolated-white-background-kitchen-appliances_134830-658.jpg?t=st=1729868962~exp=1729872562~hmac=a6e1d386538e547c0946933841df4648a0b4ccd64a984c900200c445f93c36fe&w=826",
      },
    ],
  },
  {
    title: "HealthCare",
    image: "https://s3.us-east-2.amazonaws.com/s3.zerustproducts.com/wp-content/uploads/2021/07/28193021/AdobeStock_294620656.jpeg",
    subcategories: [
      {
        title: "Vitamins",
        image: "https://img.freepik.com/free-vector/realistic-vitamin-complex-package_23-2148484944.jpg?t=st=1729869130~exp=1729872730~hmac=2dcf29e10d63569a187ad87dfa9b5bc825c2c9a3211d3004eaf369cf39a25bdf&w=826",
      },
      {
        title: "Skin Care",
        image: "https://img.freepik.com/free-photo/women-s-cosmetic-products-placed-blue_1150-17130.jpg?t=st=1729869257~exp=1729872857~hmac=ad73ce051fd8740d5dc135db6d809eaa7bb5db8fa99759f90c5c8fb2326acf7e&w=1380",
      },
      {
        title: "Fitness",
        image: "https://img.freepik.com/premium-photo/vitamin-mineral-supplements_967966-26096.jpg?w=826",
      },
    ],
  },
];

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "0",
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        centerPadding: "20px",
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: "5px",
      },
    },
  ],
};

const BestDeals = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Simulating data fetch
    const fetchData = async () => {
      // Simulate a network request delay
      await new Promise((resolve) => setTimeout(resolve, 100));
      setCategories(initialCategories);
    };

    fetchData();
  }, []);

  return (
    <div>
    <div className={`${styles.section}`}>
      <div className={`${styles.heading} text-sm md:text-base lg:text-lg text-start`}>
        <h1>Hot Categories</h1>
      </div>
      <Slider {...settings} className="mt-4 w-full ml-auto mr-auto">
        {categories.map((category, index) => (
          <div key={index} className="flex justify-center items-center px-4">
            <div className="relative p-4 w-full h-full bg-white shadow-lg rounded-lg overflow-hidden">
              <Link to={`/products?category=${encodeURIComponent(category.title)}`}>
                <div className="w-full h-40 flex items-center justify-center bg-gray-200"> {/* Placeholder background */}
                  <img
                    src={category.image}
                    alt={category.title}
                    className="w-full h-full object-cover cursor-pointer" // No border for main image
                    onError={(e) => { e.target.src = 'path/to/placeholder-image.jpg'; }} // Optional placeholder on error
                  />
                </div>
              </Link>

              <div className="grid grid-cols-3 gap-2 p-2">
                {category.subcategories.map((subcategory, subIndex) => (
                  <div key={subIndex} className="flex flex-col items-center">
                    <Link to={`/products?search=${encodeURIComponent(subcategory.title)}`}>
                      <div className="w-full h-24 flex items-center justify-center bg-gray-200 border"> {/* Placeholder background */}
                        <img
                          src={subcategory.image}
                          alt={subcategory.title}
                          className="w-full h-full object-cover rounded cursor-pointer" // No border for subcategory images
                          onError={(e) => { e.target.src = 'path/to/placeholder-image.jpg'; }} // Optional placeholder on error
                        />
                      </div>
                    </Link>
                    <Link
                      to={`/products?search=${encodeURIComponent(subcategory.title)}`}
                      className="text-sm text-center hidden md:block cursor-pointer hover:underline"
                    >
                      {subcategory.title}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="text-black text-center py-2">
                <Link
                  to={`/products?search=${encodeURIComponent(category.title)}`}
                  className="text-sm text-center cursor-pointer hover:underline"
                >
                  <h2 className="text-lg font-semibold">{category.title}</h2>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  </div>
  );
};

export default BestDeals;
