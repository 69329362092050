import React, { useState } from "react";
import { Navigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlineSearch, AiOutlineHeart, AiOutlineUser } from "react-icons/ai";
import Wishlist from "../Wishlist/Wishlist";
import { RxCross1 } from "react-icons/rx";
import { categoriesData } from "../../static/data";
import { IoGridOutline } from "react-icons/io5";
import { GrHomeRounded } from "react-icons/gr";

const BottomNav = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { allProducts } = useSelector((state) => state.products);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredProducts = allProducts.filter(
      (product) =>
        product.name.toLowerCase().includes(term) ||
        (product.shop && product.shop.name.toLowerCase().includes(term)) ||
        product.category.toLowerCase().includes(term)
    );

    setSearchData(filteredProducts);
  };

  const handleCategoryClick = (category) => {
    setOpenCategories(false);
    Navigate(`/products?category=${category.title}`);
  };

  const handleCategoryToggle = () => {
    setOpenCategories(!openCategories);
  };

  return (
    <div>
      <section
        id="bottom-navigation"
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#fff",
          boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
        }}
      >
        <div id="tabs" style={{ display: "flex", justifyContent: "space-between" }}>
          <Link
            to="/"
            style={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "10px",
              paddingBottom: "5px",
              color: "#4B5563",
              textDecoration: "none",
            }}
          >
            <GrHomeRounded className="text-[24px]" />
            <span className="tab block text-xs hover:text-[#29625d]">Home</span>
          </Link>
          <div
            style={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "10px",
              paddingBottom: "5px",
              color: openCategories ? "#4B5563" : "#4B5563",
              textDecoration: "none",
            }}
            onClick={handleCategoryToggle}
          >
            <IoGridOutline className="text-[24px]" />
            <span className="tab block text-xs hover:text-[#29625d]">Categories</span>
          </div>
          <div
            style={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "10px",
              paddingBottom: "5px",
              color: "#4B5563",
              textDecoration: "none",
            }}
            onClick={() => setOpenWishlist(true)}
          >
            <div className="relative flex flex-col items-center">
              <AiOutlineHeart size={25} className="hover:text-[#29625d]"/>
              <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 rounded-full bg-[#fed592] w-4 h-4 text-white font-mono text-[12px] leading-tight text-center">
                {wishlist && wishlist.length}
              </span>
            </div>
            <span className="tab block text-xs hover:text-[#29625d]">Wishlist</span>
          </div>
          <div
            style={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "10px",
              paddingBottom: "5px",
              color: "#4B5563",
              textDecoration: "none",
            }}
            onClick={() => (document.getElementById("searchModal").style.display = "block")}
          >
            <AiOutlineSearch size={25} className="hover:text-[#29625d]"/>
            <span className="tab block text-xs hover:text-[#29625d]">Search</span>
          </div>
          <Link
            to={isAuthenticated ? "/profile" : "/login"}
            style={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "10px",
              paddingBottom: "5px",
              color: "#4B5563",
              textDecoration: "none",
            }}
          >
            {isAuthenticated ? (
              <img
                src={user?.avatar?.url}
                className="w-[25px] h-[25px] rounded-full"
                alt="User Avatar"
              />
            ) : (
              <AiOutlineUser size={25} className="hover:text-[#29625d]"/>
            )}
            <span className="tab block text-xs hover:text-[#29625d]">My Account</span>
          </Link>
        </div>
      </section>

      {/* Search Modal */}
      <div
        id="searchModal"
        style={{
          display: "none",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 2000,
        }}
        onClick={() => (document.getElementById("searchModal").style.display = "none")}
      >
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search products..."
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ddd",
            }}
          />
          <div>
            {searchData && searchData.length !== 0 ? (
              <div className="absolute min-h-[10%] w-[90%] bg-slate-50 shadow-sm-2 z-[9] p-4">
                {searchData.map((product) => (
                  <div key={product._id} className="flex items-center py-3">
                    <Link to={`/product/${product._id}`} className="flex items-center">
                      <img
                        src={`${product.images[0]?.url}`}
                        alt=""
                        className="w-[40px] h-[40px] mr-[10px] rounded-md"
                      />
                      <div className="flex flex-col">
                        <h1 className="text-base">{product.name}</h1>
                        <Link to={`/shop/${product.shop._id}`} className="text-sm text-[#29625d]">
                          {product.shop.name}
                        </Link>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <p>No Products Found!</p>
            )}
          </div>
        </div>
      </div>

      {/* Wishlist Popup */}
      {openWishlist && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "90%",
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <button
              onClick={() => setOpenWishlist(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
            <Wishlist setOpenWishlist={setOpenWishlist} />
          </div>
        </div>
      )}

      {/* Categories Modal */}
      {openCategories && (
        <div
          className="fixed w-full bg-[#4B5563] z-50 h-full top-0 left-0"
          onClick={() => setOpenCategories(false)}
        >
          <div className="fixed w-[100%] bg-[#fff] h-screen top-0 left-0 z-50 overflow-y-scroll">
            <div className="w-full justify-between flex pr-3">
              <RxCross1
                size={30}
                className="ml-4 mt-5 cursor-pointer"
                onClick={() => setOpenCategories(false)}
              />
            </div>
            <h3 className="ml-4 font-[500] mt-7">All Categories</h3>
            <div className="flex justify-center items-center mt-3">
              <div className="grid grid-cols-2 gap-[10px]">
                {categoriesData &&
                  categoriesData.map((category) => (
                    <div
                      className="w-full border-[1px] rounded-md p-1 h-[80px] m-1 flex items-center justify-between cursor-pointer overflow-hidden"
                      key={category.id}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <h5 className="text-[18px] w-full leading-[1.3]">{category.title}</h5>
                      <img
                        src={category.image_Url}
                        className="w-[50px] h-[50px] block object-cover"
                        alt={category.title}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BottomNav;
