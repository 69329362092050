import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { server } from "../../server";
import styles from "../../styles/styles";
import { addToWishlist, removeFromWishlist } from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  FaDiscord,
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaReddit,
} from "react-icons/fa";


const ProductDetails = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);

  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(getAllProductsShop(data.shop._id));
      setClick(wishlist.some((i) => i._id === data._id));
    }
  }, [data, wishlist, dispatch]);

  const incrementCount = () => setCount((prev) => prev + 1);
  const decrementCount = () => count > 1 && setCount((prev) => prev - 1);

  const toggleWishlist = () => {
    setClick((prev) => !prev);
    click ? dispatch(removeFromWishlist(data)) : dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart.some((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else if (data.stock < 1) {
      toast.error("Product stock limited!");
    } else {
      dispatch(addTocart({ ...data, qty: count }));
      toast.success("Item added to cart successfully!");
    }
  };

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = `${data._id}${user._id}`;
      const userId = user._id;
      const sellerId = data.shop._id;
      try {
        const res = await axios.post(`${server}/conversation/create-new-conversation`, { groupTitle, userId, sellerId });
        navigate(`/inbox?${res.data.conversation._id}`);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  const formatPrice = (price) =>
    price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const totalReviewsLength = products?.reduce(
    (acc, product) => acc + product.reviews.length,
    0
  );

  const totalRatings = products?.reduce(
    (acc, product) => acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
    0
  );

  const averageRating = (totalRatings / totalReviewsLength || 0).toFixed(2);

  const ShareButtons = () => {
    const shareLinks = {
      facebook: "https://www.facebook.com/sharer/sharer.php?u=YOUR_URL",
      pinterest: "https://pinterest.com/pin/create/button/?url=YOUR_URL",
      instagram: "https://www.instagram.com/sharing/",
      discord: "https://discord.com/share?url=YOUR_URL",
      reddit: "https://www.reddit.com/submit?url=YOUR_URL",
    };

    const handleShare = (url) => {
      window.open(url, "_blank");
    };

    return (
      <div className="mt-2 p-2 flex justify-start items-center space-x-2">
        <h2>SHARE: </h2>
        {Object.entries(shareLinks).map(([platform, url]) => {
          const Icon = { facebook: FaFacebook, pinterest: FaPinterest, instagram: FaInstagram, discord: FaDiscord, reddit: FaReddit }[platform];
          return (
            <Icon
              key={platform}
              className="cursor-pointer text-[24px] h-8 w-8 text-white bg-[#29625d] rounded-full p-2 hover:text-black transition duration-200"
              onClick={() => handleShare(url)}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="bg-white">
      {data ? (
        <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5">
            <div className="block w-full 800px:flex relative">
              {/* Main Image Container */}
              <div className="w-full h-[400px] md:w-[50%]">
                <div className="relative">
                  <img
                    src={data.images[select]?.url}
                    alt=""
                    className="w-[80%]"
                  />
                  {/* Overlay Images */}
                  <div className="absolute right-5 top-2 flex flex-col space-y-1 cursor-pointer">
                    {data.images.slice(0, 4).map((img, index) => (
                      <img
                        key={index}
                        src={img.url}
                        alt=""
                        className="h-[60px] w-[60px] object-cover rounded-md border bg-white p-1"
                        onClick={() => setSelect(index)}
                      />
                    ))}
                  </div>


                </div>

              </div>
              {/* Product Info Container */}
              <div className="w-full 800px:w-[50%] pt-5">
                <h1 className={`${styles.productTitle} text-md sm:text-xl md:text-2xl lg:text-2xl`}>
                  {data.name}
                </h1>
                <div className="flex pt-3">
                  <h4 className={`${styles.productDiscountPrice} text-base sm:text-lg md:text-xl lg:text-2xl`}>
                    {formatPrice(data.discountPrice)}<span className="text-sm ml-1">RWF</span>
                  </h4>
                  <h3 className={`${styles.price} text-sm sm:text-base md:text-lg lg:text-xl`}>
                    {data.originalPrice ? formatPrice(data.originalPrice) : null}
                  </h3>
                </div>
                <div className="flex mt-4">
                <Ratings rating={data.ratings} /> <span className="text-gray-700">({data.reviews.length} Reviews)</span>
                  </div>
                <p className="text-gray-400 text-base font-bold mt-3 uppercase">Product description:</p>
                <div className="py-2 text-sm sm:text-base md:text-md lg:text-md">
                  <ReactQuill value={data.description} readOnly theme="bubble" />
                </div>

                <div className="flex items-start mt-1 pr-3 space-x-2">
                  <div className="flex items-center border rounded-full overflow-hidden shadow-lg">
                    <button className="text-gray-600 font-bold border-r border-gray-300 lg:text-md px-3 py-2 flex items-center justify-center hover:opacity-75 transition duration-300 ease-in-out" onClick={decrementCount}>
                      -
                    </button>
                    <span className="text-gray-800 text-sm sm:text-base md:text-md lg:text-lg border-r border-gray-300 flex items-center justify-center px-3 py-2">
                      {count}
                    </span>
                    <button className="text-gray-600 font-bold lg:text-md px-3 py-2 flex items-center justify-center hover:opacity-75 transition duration-300 ease-in-out" onClick={incrementCount}>
                      +
                    </button>
                  </div>

                  <div className="flex items-center px-4 bg-black px-4 py-2 rounded-full mt-1 cursor-pointer hover:bg-[#29635d]" onClick={() => addToCartHandler(data._id)}>
                    <span className="text-white flex items-center ">
                      <AiOutlineShoppingCart className="mr-1" />
                      Add to cart
                    </span>
                  </div>

                  <div className="p-2 bg-gray-200 rounded-full">
                    {click ? (
                      <AiFillHeart size={30} className="cursor-pointer" onClick={toggleWishlist} color="red" title="Remove from wishlist" />
                    ) : (
                      <AiOutlineHeart size={30} className="cursor-pointer" onClick={toggleWishlist} color="#333" title="Add to wishlist" />
                    )}
                  </div>
                </div>
                <div className="flex items-center pt-8">
                  <Link to={`/shop/preview/${data.shop._id}`}>
                    <img src={data.shop.avatar.url} alt="" className="w-[50px] h-[50px] rounded-full mr-2 border" />
                  </Link>
                  <div className="pr-8">
                    <Link to={`/shop/preview/${data.shop._id}`}>
                      <h3 className={`${styles.shop_name} pb-1 pt-1 text-sm sm:text-base md:text-md lg:text-md`}>
                        {data.shop.name}
                      </h3>
                    </Link>
                    <h5 className="pb-3 mt-[-10px] text-xs sm:text-sm md:text-base lg:text-md">
                      ({averageRating}/5) Ratings
                    </h5>
                  </div>
                  <div className="bg-[#29625d] p-2 rounded-md cursor-pointer hover:bg-black" onClick={handleMessageSubmit}>
                    <span className="text-white flex items-center font-bold">
                      Send Message
                    </span>
                  </div>
                </div>
                <hr className="mt-12"></hr>
                <ShareButtons />
              </div>
            </div>
          </div>
          <ProductDetailsInfo
            data={data}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
          />
          <br />
          <br />
        </div>
      ) : (
        <div>No product details available.</div>
      )}
    </div>
  );
};

const ProductDetailsInfo = ({ data, products, totalReviewsLength, averageRating }) => {
  const [active, setActive] = useState(1);

  return (
    <div className="bg-[#f5f6fb] px-3 800px:px-10 py-2 rounded">
      <div className="w-full flex justify-between border-b pt-10 pb-2">
        <div className="relative">
          <h5 className="text-[#000] text-sm sm:text-base md:text-lg lg:text-xl px-1 leading-5 font-[600] cursor-pointer" onClick={() => setActive(1)}>
            Product Details
          </h5>
          {active === 1 && <div className={`${styles.active_indicator}`} />}
        </div>
        <div className="relative">
          <h5 className="text-[#000] text-sm sm:text-base md:text-lg lg:text-xl px-1 leading-5 font-[600] cursor-pointer" onClick={() => setActive(2)}>
            Product Reviews
          </h5>
          {active === 2 && <div className={`${styles.active_indicator}`} />}
        </div>
        <div className="relative">
          <h5 className="text-[#000] text-sm sm:text-base md:text-lg lg:text-xl px-1 leading-5 font-[600] cursor-pointer" onClick={() => setActive(3)}>
            Seller Information
          </h5>
          {active === 3 && <div className={`${styles.active_indicator}`} />}
        </div>
      </div>

      {active === 1 && (
        <>
          <div className="py-2 text-sm sm:text-base md:text-md lg:text-md">
            <ReactQuill value={data.description} readOnly theme="bubble" />
          </div>
          <p className="py-2 text-sm sm:text-base md:text-lg lg:text-xl">{data.details}</p>
        </>
      )}

      {active === 2 && (
        <div className="w-full min-h-[40vh] flex flex-col items-center py-3 overflow-y-scroll">
          {data.reviews.map((item, index) => (
            <div className="w-full flex my-2" key={index}>
              <img src={item.user.avatar.url} className="w-[50px] h-[50px] rounded-full" alt="" />
              <div className="pl-2">
                <div className="w-full flex items-center">
                  <h1 className="font-[500] mr-3 text-sm sm:text-base md:text-lg lg:text-xl">{item.user.name}</h1>
                  <Ratings rating={data.ratings} />
                </div>
                <p className="text-xs sm:text-sm md:text-base lg:text-md">{item.comment}</p>
              </div>
            </div>
          ))}
          {data.reviews.length === 0 && <h5>No Reviews yet!</h5>}
        </div>
      )}

      {active === 3 && (
        <div className="w-full block 800px:flex p-5">
          <div className="w-full 800px:w-[50%]">
            <div className="flex items-center">
              <img src={data.shop.avatar.url} className="w-[50px] h-[50px] rounded-full" alt="" />
              <div className="pl-3">
                <h3 className={`${styles.shop_name} text-sm sm:text-base md:text-lg lg:text-xl`}>
                  {data.shop.name}
                </h3>
                <h5 className="pb-2 text-xs sm:text-sm md:text-base lg:text-md">
                  ({averageRating}/5) Ratings
                </h5>
              </div>
            </div>
            <div className="py-2 text-sm sm:text-base md:text-md lg:text-md">
              <ReactQuill value={data.shop.description} readOnly theme="bubble" />
            </div>
          </div>
          <div className="w-full 800px:w-[50%] mt-5 800px:mt-0 800px:flex 800px:flex-col 800px:items-end">
            <div className="text-left 800px:text-right">
              <h5 className="font-[600] text-xs sm:text-sm md:text-base lg:text-md">
                Joined on:{" "}
                <span className="font-[500]">{data.shop.createdAt?.slice(0, 10)}</span>
              </h5>
              <h5 className="font-[600] pt-3 text-xs sm:text-sm md:text-base lg:text-md">
                Total Products:{" "}
                <span className="font-[500]">{products.length}</span>
              </h5>
              <h5 className="font-[600] pt-3 text-xs sm:text-sm md:text-base lg:text-md">
                Total Reviews:{" "}
                <span className="font-[500]">{totalReviewsLength}</span>
              </h5>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
