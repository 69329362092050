import React, { useState } from 'react';
import { categoriesData } from '../../static/data';
const DropDownFilter = ({ categoryData, handleCategoryChange }) => {
  const [priceRange, setPriceRange] = useState([100, 10000000]); // Keeping for later use
  const [condition, setCondition] = useState(""); // Keeping condition for later use
  const [isWholesale, setIsWholesale] = useState(false); // Keeping for later use
  const [isFlashSale, setIsFlashSale] = useState(false); // Keeping for later use
  const [isDailyDeal, setIsDailyDeal] = useState(false); // Keeping for later use

  return (
    <div className="w-full p-6 bg-white shadow-lg">
      <h2 className="font-bold text-lg mb-4 text-gray-800">Filter Options</h2>

      {/* Category Filter */}
      <div className="mb-6">
        <h3 className="font-semibold text-black">Category</h3>
        <div className="flex flex-col">
          {categoriesData.map((category) => (
            <label key={category.id} className="flex items-center mb-2 cursor-pointer">
              <input
                type="checkbox"
                checked={categoryData === category.title}
                onChange={() => handleCategoryChange(category.title)}
                className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <span className="text-gray-600 text-[14px]">{category.title}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Price Range Filter (Disabled for now) */}
      <div className="mb-6">
        <h3 className="font-semibold text-gray-700">Price Range (Disabled)</h3>
        <input
          type="range"
          min="0"
          max="10000000"
          value={priceRange[0]}
          onChange={(e) => setPriceRange([Number(e.target.value), priceRange[1]])}
          className="w-full mb-2"
          disabled
        />
        <input
          type="range"
          min="0"
          max="10000000"
          value={priceRange[1]}
          onChange={(e) => setPriceRange([priceRange[0], Number(e.target.value)])}
          className="w-full mb-4"
          disabled
        />
      </div>

      {/* Condition Filter (Disabled for now) */}
      <div className="mb-6">
        <h3 className="font-semibold text-gray-700">Condition (Disabled)</h3>
        <select
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
          className="w-full mb-2 border rounded-md shadow-sm"
          disabled
        >
          <option value="">All Conditions</option>
          <option value="new">New</option>
          <option value="used">Used</option>
        </select>
      </div>

      {/* Wholesale Filter (Disabled for now) */}
      <div className="mb-6">
        <label className="flex items-center mb-2 cursor-pointer">
          <input
            type="checkbox"
            checked={isWholesale}
            onChange={() => setIsWholesale(!isWholesale)}
            className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            disabled
          />
          <span className="text-gray-600 text-[14px]">Wholesale (Disabled)</span>
        </label>
      </div>

      {/* Flash Sale Filter (Disabled for now) */}
      <div className="mb-6">
        <label className="flex items-center mb-2 cursor-pointer">
          <input
            type="checkbox"
            checked={isFlashSale}
            onChange={() => setIsFlashSale(!isFlashSale)}
            className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            disabled
          />
          <span className="text-gray-600 text-[14px]">Flash Sale (Disabled)</span>
        </label>
      </div>

      {/* Daily Deal Filter (Disabled for now) */}
      <div className="mb-6">
        <label className="flex items-center mb-2 cursor-pointer">
          <input
            type="checkbox"
            checked={isDailyDeal}
            onChange={() => setIsDailyDeal(!isDailyDeal)}
            className="mr-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            disabled
          />
          <span className="text-gray-600 text-[14px]">Daily Deal (Disabled)</span>
        </label>
      </div>
    </div>
  );
};

export default DropDownFilter;
