import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Layout/Header";
import { useSelector } from "react-redux";
import socketIO from "socket.io-client";
import { format } from "timeago.js";
import { server } from "../server";
import axios from "axios";
import { AiOutlineArrowRight, AiOutlineSend } from "react-icons/ai";
import { TfiGallery } from "react-icons/tfi";
import BottomNav from "../components/Layout/BottomNav";

// Notifier Component
const Notifier = ({ message, onClose }) => {
  if (!message) return null;

  return (
    <div className="fixed top-4 right-4 p-3 bg-red-600 text-white rounded-lg shadow-lg flex items-center space-x-2">
      <p>{message}</p>
      <button onClick={onClose} className="text-xl font-semibold">✕</button>
    </div>
  );
};

const ENDPOINT = "https://guriraline-socket.onrender.com";
const socket = socketIO(ENDPOINT, { transports: ["websocket"] });

const UserInbox = () => {
  const { user, loading } = useSelector((state) => state.user);
  const [conversations, setConversations] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [currentChat, setCurrentChat] = useState(null); // Initialize as null
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userData, setUserData] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [images, setImages] = useState(null);
  const [activeStatus, setActiveStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [notifierMessage, setNotifierMessage] = useState("");
  const scrollRef = useRef(null);

  useEffect(() => {
    socket.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        images: data.images,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    if (arrivalMessage && currentChat?.members.includes(arrivalMessage.sender)) {
      setMessages((prev) => [...prev, arrivalMessage]);
    }
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    const getConversations = async () => {
      try {
        const response = await axios.get(`${server}/conversation/get-all-conversation-user/${user?._id}`, {
          withCredentials: true,
        });

        const allConversations = response.data.conversations;
        const uniqueConversations = new Set();
        const conversationsWithUniqueMembers = [];

        for (const conv of allConversations) {
          const res = await axios.get(`${server}/message/get-all-messages/${conv._id}`);
          const members = res.data.messages.length > 0 ? res.data.messages[0].members : []; // Check if there are messages

          // Create a unique member key only if there are members
          const memberKey = members.length > 0 ? [...new Set(members)].sort().join(',') : null;

          // If there are no members or if the member key is unique, add the conversation
          if (!memberKey || !uniqueConversations.has(memberKey)) {
            if (memberKey) {
              uniqueConversations.add(memberKey);
            }
            conversationsWithUniqueMembers.push(conv);
          }
        }

        setConversations(conversationsWithUniqueMembers);
      } catch (error) {
        console.error(error);
      }
    };

    getConversations();
  }, [user]);


  useEffect(() => {
    if (user) {
      socket.emit("addUser", user._id);
      socket.on("getUsers", (data) => {
        setOnlineUsers(data);
      });
    }
  }, [user]);

  const onlineCheck = (chat) => {
    const chatMembers = chat.members.find((member) => member !== user._id);
    return onlineUsers.some((user) => user.userId === chatMembers);
  };

  useEffect(() => {
    const getMessages = async () => {
      if (currentChat?._id) {
        try {
          const response = await axios.get(`${server}/message/get-all-messages/${currentChat._id}`);
          setMessages(response.data.messages);
        } catch (error) {
          console.error(error);
        }
      }
    };
    getMessages();
  }, [currentChat]);

  const containsURLorPhoneNumber = (text) => {
    const urlPattern = /https?:\/\/[^\s]*\.[^\s]+/g;
    const phonePattern = /\b\d{10,}\b/g;
    return urlPattern.test(text) || phonePattern.test(text);
  };

  const sendMessageHandler = async (e) => {
    e.preventDefault();

    if (containsURLorPhoneNumber(newMessage)) {
      setNotifierMessage("Messages containing URLs or phone numbers are not allowed.");
      setTimeout(() => setNotifierMessage(""), 3000);
      return;
    }

    const message = {
      sender: user._id,
      text: newMessage,
      conversationId: currentChat._id,
    };
    const receiverId = currentChat.members.find((member) => member !== user._id);

    socket.emit("sendMessage", {
      senderId: user._id,
      receiverId,
      text: newMessage,
    });

    try {
      if (newMessage !== "") {
        const res = await axios.post(`${server}/message/create-new-message`, message);
        setMessages([...messages, res.data.message]);
        updateLastMessage();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateLastMessage = async () => {
    socket.emit("updateLastMessage", {
      lastMessage: newMessage,
      lastMessageId: user._id,
    });

    try {
      await axios.put(`${server}/conversation/update-last-message/${currentChat._id}`, {
        lastMessage: newMessage,
        lastMessageId: user._id,
      });
      setNewMessage("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageUpload = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImages(reader.result);
        imageSendingHandler(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const imageSendingHandler = async (image) => {
    const receiverId = currentChat.members.find((member) => member !== user._id);

    socket.emit("sendMessage", {
      senderId: user._id,
      receiverId,
      images: image,
    });

    try {
      const res = await axios.post(`${server}/message/create-new-message`, {
        images: image,
        sender: user._id,
        text: newMessage,
        conversationId: currentChat._id,
      });
      setImages(null);
      setMessages([...messages, res.data.message]);
      updateLastMessageForImage();
    } catch (error) {
      console.error(error);
    }
  };

  const updateLastMessageForImage = async () => {
    try {
      await axios.put(`${server}/conversation/update-last-message/${currentChat._id}`, {
        lastMessage: "Photo",
        lastMessageId: user._id,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="w-full min-h-screen bg-gray-100">
      <Notifier message={notifierMessage} onClose={() => setNotifierMessage("")} />
      {!open ? (
        <>
          <Header />
          <h3 className="text-center text-3xl py-3 font-bold text-gray-700">All Messages</h3>
          <div className="flex flex-col max-w-md mx-auto">
            {conversations.map((item, index) => (
              <MessageList
                data={item}
                key={index}
                index={index}
                setOpen={setOpen}
                setCurrentChat={setCurrentChat}
                me={user?._id}
                setUserData={setUserData}
                userData={userData}
                online={onlineCheck(item)}
                setActiveStatus={setActiveStatus}
                loading={loading}
              />
            ))}
          </div>
        </>
      ) : (
        <SellerInbox
          setOpen={setOpen}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          sendMessageHandler={sendMessageHandler}
          messages={messages}
          sellerId={user._id}
          userData={userData}
          activeStatus={activeStatus}
          scrollRef={scrollRef}
          handleImageUpload={handleImageUpload}
        />
      )}
    </div>
  );
};

const MessageList = ({
  data,
  index,
  setOpen,
  setCurrentChat,
  me,
  setUserData,
  userData,
  online,
  setActiveStatus,
  loading,
}) => {
  const [user, setUser] = useState(null);
  const [active, setActive] = useState(null);

  useEffect(() => {
    const userId = data.members.find((user) => user !== me);
    const getUser = async () => {
      try {
        const res = await axios.get(`${server}/shop/get-shop-info/${userId}`);
        setUser(res.data.shop);
      } catch (error) {
        console.error(error);
      }
    };
    getUser();
  }, [me, data]);

  return (
    <div
      className={`w-full flex p-3 px-4 py-1 rounded-lg transition-all duration-200 ease-in-out ${active === index ? "bg-gray-200" : "bg-white"} hover:bg-gray-50 cursor-pointer shadow-md`}
      onClick={() => {
        setActive(index);
        setCurrentChat(data);
        setUserData(user);
        setActiveStatus(online);
        setOpen(true); // Ensure the chat opens when selected
      }}
    >
      <div className="relative">
        <img
          src={`${user?.avatar?.url}`}
          alt="User Avatar"
          className="w-12 h-12 rounded-full border-2 border-gray-300"
        />
        {online ? (
          <div className="w-3 h-3 bg-green-500 rounded-full absolute bottom-1 right-1 border-2 border-white" />
        ) : (
          <div className="w-3 h-3 bg-gray-400 rounded-full absolute bottom-1 right-1 border-2 border-white" />
        )}
      </div>
      <div className="pl-3 flex-1">
        <h1 className="text-lg font-semibold text-gray-800">{user?.name}</h1>
        <p className="text-md text-gray-600">
          {!loading && data?.lastMessageId !== userData?._id
            ? "You:"
            : userData?.name.split(" ")[0] + ": "}{" "}
          {data?.lastMessage}
        </p>
      </div>
    </div>
  );
};

const SellerInbox = ({
  setOpen,
  newMessage,
  setNewMessage,
  sendMessageHandler,
  messages,
  sellerId,
  userData,
  activeStatus,
  scrollRef,
  handleImageUpload,
}) => {
  return (
    <div className="w-full min-h-screen flex flex-col bg-white shadow-lg mb-20">
      {/* Header */}
      <div className="w-full flex p-4 items-center justify-between bg-gray-200 border-b border-gray-300">
        <div className="flex items-center space-x-3">
          <img
            src={`${userData?.avatar?.url}`}
            alt="User Avatar"
            className="w-16 h-16 rounded-full"
          />
          <div>
            <h1 className="text-lg font-semibold text-gray-800">{userData?.name}</h1>
            <h2 className={`text-sm ${activeStatus ? "text-green-600" : "text-gray-500"}`}>
              {activeStatus ? "Active Now" : "Offline"}
            </h2>
          </div>
        </div>
        <AiOutlineArrowRight
          size={24}
          className="cursor-pointer text-gray-600"
          onClick={() => setOpen(false)}
        />
      </div>

      {/* Messages Container */}
      <div className="flex-1 overflow-y-auto px-4 py-3 pb-20"> {/* Added pb-20 to ensure space for the fixed form */}
        {messages.map((item, index) => (
          <div
            className={`flex items-start my-2 ${item.sender === sellerId ? "justify-end" : "justify-start"}`}
            ref={scrollRef}
            key={index}
          >
            {item.sender !== sellerId && (
              <img
                src={`${userData?.avatar?.url}`}
                className="w-10 h-10 rounded-full mr-3"
                alt="Sender Avatar"
              />
            )}
            {item.images && (
              <img src={`${item.images}`} alt="Sent" className="w-64 h-64 object-cover rounded-lg" />
            )}
            {item.text && (
              <div className="p-2 rounded-lg text-white">
                <p className={`p-1 rounded-lg ${item.sender === sellerId ? "bg-gray-800" : "bg-teal-500"}`}>
                  {item.text}
                </p>
                <p className="text-xs text-gray-300 pt-1">{format(item.createdAt)}</p>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Fixed Form */}
      <form className="fixed mb-12 bottom-0 left-0 w-full p-4 flex items-center bg-gray-100 border-t border-gray-300" onSubmit={sendMessageHandler}>
        <div className="w-8">
          <input
            type="file"
            id="image"
            className="hidden"
            onChange={handleImageUpload}
          />
          <label htmlFor="image">
            <TfiGallery className="text-teal-600 cursor-pointer" size={24} />
          </label>
        </div>
        <div className="flex-1 mx-2">
          <input
            type="text"
            required
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="w-full p-3 border rounded-lg border-gray-300"
          />
        </div>
        <button type="submit" className="text-teal-600">
          <AiOutlineSend size={24} />
        </button>
      </form>
      <BottomNav />
    </div>
  );
};

export default UserInbox;
