import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const products = [
  {
    id: 1,
    name: 'Fashion',
    price: 'Starting from RWF 3000.00',
    imageUrl: 'https://images.unsplash.com/photo-1620799140408-edc6dcb6d633?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNsb3RoZXN8ZW58MHx8MHx8fDA%3D',
  },
  {
    id: 2,
    name: 'Electronics',
    price: 'Starting from RWF 20,000',
    imageUrl: 'https://images.unsplash.com/photo-1593344484962-796055d4a3a4?q=80&w=1430&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 3,
    name: 'Home Decor',
    price: 'Starting from RWF 5000',
    imageUrl: 'https://images.unsplash.com/photo-1534349762230-e0cadf78f5da?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  // Add more products as needed
];

const ProductCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Change slide every 3 seconds
    arrows: false, // Hide arrows
  };

  return (
    <div className="bg-gray-100 p-4">
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product.id} className="p-4">
            <div
              className="relative bg-cover bg-center rounded-lg overflow-hidden"
              style={{ backgroundImage: `url(${product.imageUrl})`, height: '200px' }}
            >
              <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-50"></div>
              <div className="absolute bottom-0 p-4 bg-gradient-to-t from-black via-transparent to-transparent text-white w-full">
                <h3 className="text-2xl font-semibold mb-1">{product.name}</h3>
                <p className="text-lg">{product.price}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductCarousel;
